// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-video-guides-js": () => import("/opt/build/repo/src/templates/video-guides.js" /* webpackChunkName: "component---src-templates-video-guides-js" */),
  "component---src-templates-challenges-js": () => import("/opt/build/repo/src/templates/challenges.js" /* webpackChunkName: "component---src-templates-challenges-js" */),
  "component---src-templates-presets-js": () => import("/opt/build/repo/src/templates/presets.js" /* webpackChunkName: "component---src-templates-presets-js" */),
  "component---src-templates-presets-featured-js": () => import("/opt/build/repo/src/templates/presets-featured.js" /* webpackChunkName: "component---src-templates-presets-featured-js" */),
  "component---src-templates-challenge-detail-js": () => import("/opt/build/repo/src/templates/challenge-detail.js" /* webpackChunkName: "component---src-templates-challenge-detail-js" */),
  "component---src-templates-preset-detail-js": () => import("/opt/build/repo/src/templates/preset-detail.js" /* webpackChunkName: "component---src-templates-preset-detail-js" */),
  "component---src-templates-preset-creator-js": () => import("/opt/build/repo/src/templates/preset-creator.js" /* webpackChunkName: "component---src-templates-preset-creator-js" */),
  "component---src-templates-preset-device-js": () => import("/opt/build/repo/src/templates/preset-device.js" /* webpackChunkName: "component---src-templates-preset-device-js" */),
  "component---src-templates-preset-category-js": () => import("/opt/build/repo/src/templates/preset-category.js" /* webpackChunkName: "component---src-templates-preset-category-js" */),
  "component---src-pages-dashboard-js": () => import("/opt/build/repo/src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-discord-identify-js": () => import("/opt/build/repo/src/pages/discord-identify.js" /* webpackChunkName: "component---src-pages-discord-identify-js" */),
  "component---src-pages-forum-js": () => import("/opt/build/repo/src/pages/forum.js" /* webpackChunkName: "component---src-pages-forum-js" */),
  "component---src-pages-gridniks-js": () => import("/opt/build/repo/src/pages/gridniks.js" /* webpackChunkName: "component---src-pages-gridniks-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-validatemail-js": () => import("/opt/build/repo/src/pages/validatemail.js" /* webpackChunkName: "component---src-pages-validatemail-js" */),
  "component---src-pages-wiki-audio-mdx": () => import("/opt/build/repo/src/pages/wiki/audio.mdx" /* webpackChunkName: "component---src-pages-wiki-audio-mdx" */),
  "component---src-pages-wiki-automation-mdx": () => import("/opt/build/repo/src/pages/wiki/automation.mdx" /* webpackChunkName: "component---src-pages-wiki-automation-mdx" */),
  "component---src-pages-wiki-contributing-mdx": () => import("/opt/build/repo/src/pages/wiki/contributing.mdx" /* webpackChunkName: "component---src-pages-wiki-contributing-mdx" */),
  "component---src-pages-wiki-faq-mdx": () => import("/opt/build/repo/src/pages/wiki/faq.mdx" /* webpackChunkName: "component---src-pages-wiki-faq-mdx" */),
  "component---src-pages-wiki-grid-mdx": () => import("/opt/build/repo/src/pages/wiki/grid.mdx" /* webpackChunkName: "component---src-pages-wiki-grid-mdx" */),
  "component---src-pages-wiki-effects-mdx": () => import("/opt/build/repo/src/pages/wiki/effects.mdx" /* webpackChunkName: "component---src-pages-wiki-effects-mdx" */),
  "component---src-pages-wiki-community-mdx": () => import("/opt/build/repo/src/pages/wiki/community.mdx" /* webpackChunkName: "component---src-pages-wiki-community-mdx" */),
  "component---src-pages-wiki-index-mdx": () => import("/opt/build/repo/src/pages/wiki/index.mdx" /* webpackChunkName: "component---src-pages-wiki-index-mdx" */),
  "component---src-pages-wiki-learning-mdx": () => import("/opt/build/repo/src/pages/wiki/learning.mdx" /* webpackChunkName: "component---src-pages-wiki-learning-mdx" */),
  "component---src-pages-wiki-midi-mdx": () => import("/opt/build/repo/src/pages/wiki/midi.mdx" /* webpackChunkName: "component---src-pages-wiki-midi-mdx" */),
  "component---src-pages-wiki-modulation-mdx": () => import("/opt/build/repo/src/pages/wiki/modulation.mdx" /* webpackChunkName: "component---src-pages-wiki-modulation-mdx" */),
  "component---src-pages-wiki-instruments-mdx": () => import("/opt/build/repo/src/pages/wiki/instruments.mdx" /* webpackChunkName: "component---src-pages-wiki-instruments-mdx" */),
  "component---src-pages-wiki-tips-tricks-extracting-data-from-the-grid-mdx": () => import("/opt/build/repo/src/pages/wiki/tips-tricks/extracting-data-from-the-grid.mdx" /* webpackChunkName: "component---src-pages-wiki-tips-tricks-extracting-data-from-the-grid-mdx" */),
  "component---src-pages-wiki-tips-tricks-50-limitation-relative-automations-mdx": () => import("/opt/build/repo/src/pages/wiki/tips-tricks/50-limitation-relative-automations.mdx" /* webpackChunkName: "component---src-pages-wiki-tips-tricks-50-limitation-relative-automations-mdx" */),
  "component---src-pages-wiki-tips-tricks-generating-sub-harmonics-through-re-synthesis-mdx": () => import("/opt/build/repo/src/pages/wiki/tips-tricks/generating-sub-harmonics-through-re-synthesis.mdx" /* webpackChunkName: "component---src-pages-wiki-tips-tricks-generating-sub-harmonics-through-re-synthesis-mdx" */),
  "component---src-pages-wiki-tips-tricks-how-to-navigate-the-entirety-of-the-popup-browser-with-keystrokes-mdx": () => import("/opt/build/repo/src/pages/wiki/tips-tricks/how-to-navigate-the-entirety-of-the-popup-browser-with-keystrokes.mdx" /* webpackChunkName: "component---src-pages-wiki-tips-tricks-how-to-navigate-the-entirety-of-the-popup-browser-with-keystrokes-mdx" */),
  "component---src-pages-wiki-tips-tricks-index-mdx": () => import("/opt/build/repo/src/pages/wiki/tips-tricks/index.mdx" /* webpackChunkName: "component---src-pages-wiki-tips-tricks-index-mdx" */),
  "component---src-pages-wiki-tips-tricks-macros-to-automate-everything-mdx": () => import("/opt/build/repo/src/pages/wiki/tips-tricks/macros-to-automate-everything.mdx" /* webpackChunkName: "component---src-pages-wiki-tips-tricks-macros-to-automate-everything-mdx" */),
  "component---src-pages-wiki-tips-tricks-monitor-bus-mdx": () => import("/opt/build/repo/src/pages/wiki/tips-tricks/monitor-bus.mdx" /* webpackChunkName: "component---src-pages-wiki-tips-tricks-monitor-bus-mdx" */),
  "component---src-pages-wiki-tips-tricks-mute-disable-mdx": () => import("/opt/build/repo/src/pages/wiki/tips-tricks/mute-disable.mdx" /* webpackChunkName: "component---src-pages-wiki-tips-tricks-mute-disable-mdx" */),
  "component---src-pages-wiki-tips-tricks-quick-percussions-slicing-mdx": () => import("/opt/build/repo/src/pages/wiki/tips-tricks/quick-percussions-slicing.mdx" /* webpackChunkName: "component---src-pages-wiki-tips-tricks-quick-percussions-slicing-mdx" */),
  "component---src-pages-wiki-tips-tricks-switching-tracks-mdx": () => import("/opt/build/repo/src/pages/wiki/tips-tricks/switching-tracks.mdx" /* webpackChunkName: "component---src-pages-wiki-tips-tricks-switching-tracks-mdx" */),
  "component---src-pages-wiki-tips-tricks-quantize-audio-so-it-sounds-clean-without-warping-mdx": () => import("/opt/build/repo/src/pages/wiki/tips-tricks/quantize-audio-so-it-sounds-clean-without-warping.mdx" /* webpackChunkName: "component---src-pages-wiki-tips-tricks-quantize-audio-so-it-sounds-clean-without-warping-mdx" */),
  "component---src-pages-wiki-tips-tricks-track-freeze-mdx": () => import("/opt/build/repo/src/pages/wiki/tips-tricks/track-freeze.mdx" /* webpackChunkName: "component---src-pages-wiki-tips-tricks-track-freeze-mdx" */),
  "component---src-pages-wiki-tips-tricks-using-relative-automations-and-clip-launcher-mdx": () => import("/opt/build/repo/src/pages/wiki/tips-tricks/using-relative-automations-and-clip-launcher.mdx" /* webpackChunkName: "component---src-pages-wiki-tips-tricks-using-relative-automations-and-clip-launcher-mdx" */),
  "component---src-pages-wiki-ui-mdx": () => import("/opt/build/repo/src/pages/wiki/ui.mdx" /* webpackChunkName: "component---src-pages-wiki-ui-mdx" */),
  "component---src-pages-wiki-todo-mdx": () => import("/opt/build/repo/src/pages/wiki/todo.mdx" /* webpackChunkName: "component---src-pages-wiki-todo-mdx" */)
}

